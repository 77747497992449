<template>
  <div>
    <form action="#" @submit.prevent="handleSubmit">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-3">Assign {{ car ? car['name'] : 'car' }} to driver</h4>
          <form action="#">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Select driver <span class="text-danger">*</span></label>
                  <multiselect
                      v-model="driver"
                      :options="driverIds"
                      :custom-label="getDriverName"
                      :multiple="false"
                      placeholder="Select driver">
                  </multiselect>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <div class="form-group">
                  <label for="duration">Select Duration</label>
                  <select id="duration" class="form-control" v-model="duration" required>
                    <option value="day">Daily</option>
                    <option value="week">Weekly</option>
                    <option value="month">Monthly</option>
                    <option value="year">Yearly</option>
                  </select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="duration">Duration Length</label>
                  <input type="number" required min="1" v-model.number="durationLength" class="form-control">
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label>Pickup Date</label>
                  <b-form-datepicker v-model="pickupDate" locale="en"></b-form-datepicker>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label>Pickup Time</label>
                  <b-form-timepicker required
                      v-model="pickupTime"
                  ></b-form-timepicker>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label>Return Date</label>
                  <b-form-datepicker disabled v-model="returnDate" locale="en"></b-form-datepicker>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label>Return Time</label>
                  <b-form-timepicker
                      v-model="returnTime"
                  ></b-form-timepicker>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="cost" class="text-capitalize">Cost of Car per {{ duration }}</label>
                  <div class="input-group input-group-merge">
                    <input type="text" id="cost" :value="totalCarCost | toCurrencyFormat" class="form-control" disabled>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Payment Option</label>
                  <select name="taxType"  class="form-control" required v-model="payment_option">
                    <option value="send_to_weekly_payment"> Send to weekly payment</option>
                    <option value="manual_invoice"> Generate Invoice Manually</option>
                    <option value="automatic_invoice"> Generate automatically</option>
                  </select>
                </div>
              </div>
            </div>
            <hr/>
            <div v-if="!configurationLoader">
            <!--calculated fields-->
            <div class="row">
              <div class="col-md-6">
                <h2 class="header-title mb-4">
                  Calculated Fields
                </h2>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6" :class="{'mt-5' : index !== 0 && index !== 1 }"
                   v-for="(field, index) in calculatedFields">
                <div class="form-group">
                  <label for="calculated">Enter Excel Field <small class="text-bold">(separate translations with comma
                    (,)</small> <span class="text-danger">*</span></label>
                  <div class="input-group input-group-merge">
                    <input type="text" class="form-control" id="calculated"
                           placeholder="Enter label as it will appear on mobile app"
                           required
                           v-model="field.fieldName"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text bg-danger" @click="removeCalculatedField(index)"
                           style="cursor: pointer">
                        <span class="fa fa-trash text-white"></span>
                      </div>
                    </div>
                  </div>

                  <b-form-group>
                    <div class="row mt-2">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-7">
                            <input type="text" class="form-control mb-2" id="calculated"
                                   :placeholder="`${field.fieldName}'s value`"
                                   required
                                   v-model="field.fieldValue"
                            >
                          </div>
                        </div>

                      </div>
                      <div class="col-md-6">
                        <b-form-radio
                            v-model="field.fieldType" :name="'calc-type-' + index"
                            value="income">Verkoop
                        </b-form-radio>
                      </div>
                      <div class="col-md-6">
                        <b-form-radio
                            v-model="field.fieldType"
                            :name="'calc-type-' + index"
                            value="cost">
                          Inkoop
                        </b-form-radio>
                      </div>

                    </div>
                  </b-form-group>

                  <!--           Gross / Net     -->
                  <b-form-group>
                    <div class="row mt-2">
                      <div class="col-md-6">
                        <b-form-radio
                            v-model="field.grossOrNet" :name="'gross-net-' + index"
                            value="gross">Gross
                        </b-form-radio>
                      </div>
                      <div class="col-md-6">
                        <b-form-radio
                            v-model="field.grossOrNet"
                            :name="'gross-net-' + index"
                            value="net">Net
                        </b-form-radio>
                      </div>

                    </div>
                  </b-form-group>


                  <div class="row mt-2">
                    <div class="col-md-6">
                      <b-form-checkbox
                          v-model="field.weeklyPayment"
                          switch
                      >
                        Show field in weekly payments
                      </b-form-checkbox>
                    </div>
                    <div class="col-md-6">
                      <b-form-checkbox v-model="field.fAdministration"
                                       switch>
                        Show field in F. administration
                      </b-form-checkbox>
                    </div>
                  </div>

                </div>
              </div>

              <div class="col-md-12 text-right mt-4">
                <button class="btn btn-outline-dark" type="button" @click="addCalculatedField"><i
                    class="fa fa-plus"></i>
                  Add New Calc Field
                </button>
              </div>
            </div>
            <hr/>
            <!--end of calculated fields-->
            <!--tax fields-->
            <div class="row">
              <div class="col-md-6">
                <h2 class="header-title mb-4">
                  Tax Fields
                </h2>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6" :class="{'mt-5' : index !== 0 && index !== 1 }" v-for="(field, index) in taxFields">
                <div class="form-group">
                  <div class="row">
                    <div class="col-md-6">
                      <label for="calc-field">Select Reference Field <span class="text-danger">*</span></label>
                      <select name="" id="calc-field" class="form-control" @change="calculateTax(index)"
                              v-model="field.refFieldName" required>
                        <option value=""> -- select field --</option>
                        <option :value="f.fieldName" v-for="f in calculatedFields"> {{ f.fieldName }}</option>
                      </select>
                    </div>
                    <div class="col-md-6">
                      <label for="tax-type">Select Tax Type <span class="text-danger">*</span></label>
                      <select name="taxType" id="tax-type" class="form-control" v-model="field.taxType" required>
                        <option value=""> -- select field --</option>
                        <option value="upper"> Upper tax</option>
                        <option value="lower"> Lower tax</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-md-6">
                      <label for="tax-formula">Tax Formula (if applicable) <span class="text-danger">*</span></label>
                      <input type="text" id="tax-formula" class="form-control" v-model="field.formula"
                             placeholder="eg.  ref / 32 * 8" @input="calculateTax(index)">
                    </div>
                    <div class="col-md-6">
                      <label for="tax-value">Actual Value</label>
                      <input type="text" id="tax-value" class="form-control" :value="field.value" disabled>
                    </div>

                  </div>

                </div>

                <b-form-group>
                  <div class="row mt-2">
                    <div class="col-md-6">
                      <b-form-radio v-model="field.refFieldType" :name="'tax-type-' + index" value="income">Verkoop
                      </b-form-radio>
                    </div>
                    <div class="col-md-6">
                      <b-form-radio v-model="field.refFieldType" :name="'tax-type-' + index" value="cost">Inkoop
                      </b-form-radio>
                    </div>
                  </div>
                </b-form-group>

                <div class="form-group">
                  <div class="row">
                    <div class="col-md-6">
                      <b-form-checkbox v-model="field.weeklyPayment" switch>
                        Show field in weekly payments
                      </b-form-checkbox>
                    </div>
                    <div class="col-md-6">
                      <b-form-checkbox v-model="field.fAdministration" switch>
                        Show field in F. administration
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>

                <button type="button" class="btn btn-outline-danger" @click="removeTaxField(index)"><i
                    class="fa fa-trash text-danger"></i> <span class="ml-2">Delete tax field</span></button>

              </div>

              <div class="col-md-12 text-right mt-4">
                <button class="btn btn-outline-dark" type="button" @click="addTaxField"><i class="fa fa-plus"></i> Add
                  New
                  Tax Field
                </button>
              </div>

            </div>
            </div>
            <div v-else>
              Loading configurations...
            </div>
          </form>

          <div class="row">
            <div class="col-md-12 text-center">
              <button class="btn btn-dark" v-if="payment_option==='manual_invoice'" type="submit">Assign & Send Invoice</button>
              <button class="btn btn-dark" v-else type="submit">Assign Car</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import SharedDrivers from '../drivers/share-driver-list'
import {confirm} from "@/utils/functions";
import {rentalsService} from "@/apis/rentals.service";
import Multiselect from "vue-multiselect";
import {driversService} from "@/apis/drivers.service";

export default {
  name: "assign-car",
  components: {
    SharedDrivers,
    Multiselect

  },
  data() {
    return {
      configurationLoader: false,
      sendInvoice: false,
      payment_option: 'send_to_weekly_payment',
      car: null,
      pickupDate: new Date().toISOString().split('T')[0],
      returnDate: '',
      pickupTime: new Date().toTimeString().split(" ")[0].slice(0, 5),
      returnTime: '',
      driver: null,
      duration: 'week',
      durationLength: 1,
      uniqueIdFieldName: {
        hasUniqueIdField: 'Yes',
        uniqueIdFieldName: ''
      },
      totalField: {
        hasTotalField: 'No',
        totalField: ''
      },
      dateTimeField: {
        hasDateTimeField: 'No',
        dateTimeField: ''
      },
      calculatedFields: [],
      taxFields: [],
      additionalFields: [],
      car_config: null,
      drivers: [],
      carId: this.$route.params.id
    }
  },
  computed: {
    driverIds() {
      return this.drivers.map(driver => driver?.driver?.user_id);
    },
    totalCarCost() {
      const calculatedValuesSum = this.calculatedFields.reduce(
          (sum, field) => sum + (parseFloat(field.fieldValue) || 0),
          0
      );
      const taxValuesSum = this.taxFields.reduce(
          (sum, field) => sum + (parseFloat(field.value) || 0),
          0
      );
      return calculatedValuesSum + taxValuesSum;
    }
  },
  watch: {
    pickupDate: "calculateReturnDate",
    duration: "calculateReturnDate",
    durationLength: "calculateReturnDate",
    pickupTime(newTime) {
      this.returnTime = newTime;
    },
    send_to_weekly_payment(newValue) {
      this.sendInvoice = !newValue;
    },
    calculatedFields: {
      handler(newFields) {
        this.taxFields.forEach((field, index) => {
          const reference = newFields.find(
              (f) => f.fieldName === field.refFieldName
          );

          if (!field.formula || !reference) {
            field.value = 0;
            return;
          }

          try {
            const formula = field.formula.replace(/ref/g, reference.fieldValue);
            field.value = Function(`"use strict"; return (${formula})`)();
          } catch (error) {
            console.error("Invalid formula:", error);
            field.value = 0;
          }
        });
      },
      deep: true,
    },
  },
  methods: {
    calculateTax(index) {
      const field = this.taxFields[index];
      const reference = this.calculatedFields.find(
          (f) => f.fieldName === field.refFieldName
      );

      if (!field.formula || !reference) {
        field.value = 0;
        return;
      }

      try {
        const formula = field.formula.replace(/ref/g, reference.fieldValue);
        field.value = eval(formula);
      } catch (error) {
        console.error("Invalid formula:", error);
        field.value = 0;
      }
    },
    calculateReturnDate() {
      if (!this.pickupDate || !this.duration || !this.durationLength) return;

      const pickup = new Date(this.pickupDate);
      let returnDate;

      switch (this.duration) {
        case 'day':
          returnDate = new Date(pickup.setDate(pickup.getDate() + this.durationLength));
          break;
        case 'week':
          returnDate = new Date(pickup.setDate(pickup.getDate() + 7 * this.durationLength));
          break;
        case 'month':
          returnDate = new Date(pickup.setMonth(pickup.getMonth() + this.durationLength));
          break;
        case 'year':
          returnDate = new Date(pickup.setFullYear(pickup.getFullYear() + this.durationLength));
          break;
        default:
          returnDate = pickup;
      }

      this.returnDate = returnDate.toISOString().slice(0, 10); // Format as YYYY-MM-DD
      this.returnTime = this.pickupTime;
    },

    getDriverName(opt) {
      const foundDriver = this.drivers.find(x => x.driver.user_id === opt);
      return foundDriver ? foundDriver.name : '';
    },
    removeCalculatedField(index) {
      this.calculatedFields.splice(index, 1);
    },

    removeTaxField(index) {
      this.taxFields.splice(index, 1);
    },

    addCalculatedField: function (event) {
      this.calculatedFields.push({
        fieldName: '',
        fieldType: 'income', // income / cost
        grossOrNet: '',
        weeklyPayment: true,
        fAdministration: true,
      });
    },

    addTaxField: function (event) {
      this.taxFields.push({
        refFieldName: '',
        refFieldType: '', // income / cost
        taxType: '',
        value: 0,
        formula: '',
        weeklyPayment: false,
        fAdministration: true,
      });
    },
    getCarDetails(carId) {
      this.$store.dispatch('showLoader')
      rentalsService.fetchCarDetail(carId).then((response) => {
        this.$store.dispatch('hideLoader')
        if (!response.status) {
          this.$store.dispatch('error', {message: response.message, showSwal: true})
          return;
        }
        this.car = response.extra;
        if (this.car.config) {
          this.grossRiskOfDamage = this.car.config['gross_risk_of_damage'];
        }
      });
    },

    onDriverSelected(drivers) {
      console.log('selected drivers', drivers)
      if (drivers.length === 1) {
        this.driver = drivers[0];
      } else if (drivers.length < 1) {
        this.$store.dispatch('error', {message: "Please select a driver", showSwal: true})
      } else {
        this.$store.dispatch('error', {message: "Please select only one driver", showSwal: true})
      }

    },

    fetchCarConfigurations() {
      this.configurationLoader= true;
      rentalsService.getCarConfiguration(this.carId).then(response => {
        this.configurationLoader= false;
        const {status, extra} = response;
        if (!status) {
          this.$store.dispatch('error', {message: response.message});
          return;
        }
        this.car_config = extra;
        this.car_config = extra;
        if (!this.car_config) {
          this.calculatedFields = [];
          this.taxFields = [];
          this.additionalFields = [];
          return;
        }

        this.calculatedFields = JSON.parse(this.car_config.calculated_fields)
        this.taxFields = JSON.parse(this.car_config['tax_fields'])
        this.additionalFields = JSON.parse(this.car_config['additional_fields']);
        if (this.car_config['total_field']) {
          this.totalField = JSON.parse(this.car_config['total_field']);
        }

        if (this.car_config['date_time_field']) {
          this.dateTimeField = JSON.parse(this.car_config['date_time_field']);
        }

        if (this.car_config['unique_identifier']) {
          this.uniqueIdFieldName = JSON.parse(this.car_config['unique_identifier']);
        }

      })
    },

    getDrivers() {
      this.$store.dispatch('showLoader')
      driversService.getDrivers(1000, '').then(data => {
        this.$store.dispatch('hideLoader')
        if (!data.status) {
          this.$store.dispatch('error', {message: data.message});
          this.drivers = [];
          return;
        }
        this.drivers = data.extra;
      })
    },

    handleSubmit() {

      if (!this.driver) {
        this.$store.dispatch('error', {message: 'Please select a driver', showSwal: true})
        return;
      }
      const selectedDriver = this.drivers.find(d => d.id === this.driver);
      const driverName = selectedDriver ? selectedDriver.name : "Unknown Driver";

      confirm(`Rent car to ${driverName} ${this.payment_option ==='manual_invoice'?' & Send Invoice':''}` , async () => {

        const data = {
          car_id: this.carId,
          driver_id: this.driver,
          pickup_date: this.pickupDate,
          pickup_time: this.pickupTime,
          duration: this.duration,
          duration_length: this.durationLength,
          return_date: this.returnDate,
          return_time: this.returnTime,
          cost_per_period: this.totalCarCost,
          calculated_field: this.calculatedFields,
          tax_field: this.taxFields,
          payment_option: this.payment_option,
          send_invoice: this.sendInvoice,
        };

        this.$store.dispatch('assignCar', data).then(() => {
          this.$router.push(`/rentals/car-detail/${this.$route.params.id}`);
        })
      })

    },

  },
  async created() {
    await this.getCarDetails(this.carId);
    await this.fetchCarConfigurations();
    await this.getDrivers();
     this.calculateReturnDate();
  }
}

</script>

<style scoped>

</style>